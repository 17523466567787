const getModifierAdjuster = (options, modifiers) => {
  if (!(options && options.length) || !(modifiers && modifiers.length)) return options
  options.forEach(option => {
    const { optionValues } = modifiers.find(({ id }) => +id === +option.optionId) || {}
    if (optionValues && optionValues.length) {
      const { adjusters } = optionValues.find(({ id }) => +id === +option.optionValue) || {}
      option.adjusters = adjusters
    }
  })
  return options
}

const getModifierPrice = (originalPrice, options) => {
  if (!(options && options.length)) return originalPrice
  options.forEach(option => {
    const { adjusters } = option
    if (adjusters && adjusters.price) {
      const {
        price: {
          adjuster,
          adjusterValue,
        },
      } = adjusters
      if (adjusterValue) {
        switch (adjuster) {
          case 'relative': originalPrice += adjusterValue
            break
          default: originalPrice *= (1 + adjusterValue / 100)
            break
        }
      }
    }
  })
  return +originalPrice?.toFixed(2)
}

const getBulkPrice = (bulkPrices, price, qty) => {
  const {
    discountAmount: bulkPrice,
    discountType,
  } = bulkPrices.find(priceInfo => (qty >= priceInfo.minimum && qty <= (priceInfo.maximum || Infinity))) || {}
  switch (discountType) {
    case 'fixed':
      price = bulkPrice
      break
    case 'percent':
      price *= ((100 - bulkPrice) / 100)
      break
    case 'price':
      price -= bulkPrice
      break
    default:
      break
  }
  return price
}

const getCalculatedPrice = (qty, bulkPrices, originalPrice, hasPriceList, options) => {
  if (typeof (qty) !== 'number' || Number.isNaN(qty)) {
    return originalPrice || 0
  }
  let price = originalPrice
  if (!(bulkPrices && bulkPrices.length)) return getModifierPrice(originalPrice, options)
  if (hasPriceList) {
    price = getBulkPrice(bulkPrices, price, qty)
    price = getModifierPrice(price, options)
  } else {
    price = getModifierPrice(price, options)
    price = getBulkPrice(bulkPrices, price, qty)
  }
  return +price?.toFixed(2)
}

const getNewBulkPrice = (bulkPrices, price, qty, basePrice, isTax) => {
  bulkPrices.forEach(({
    minimum = 0,
    maximum = 0,
    discount_type: discountType,
    discount_amount: bulkPrice,
    tax_discount_amount: taxDiscountAmount,
  }) => {
    let taxExclusive = 0
    let taxInclusive = 0
    if (taxDiscountAmount) {
      taxExclusive = taxDiscountAmount?.tax_exclusive || 0
      taxInclusive = taxDiscountAmount?.tax_inclusive || 0
    }
    let tax = 0
    if (taxInclusive) {
      tax = taxInclusive - taxExclusive
    }

    const newPrice = isTax ? tax : bulkPrice
    if (qty >= minimum && qty <= (maximum || qty)) {
      switch (discountType) {
        case 'fixed':
          price = newPrice
          break
        case 'percent':
          basePrice *= ((newPrice) / 100)
          price -= basePrice
          break
        case 'price':
          price -= newPrice
          break
        default:
          break
      }
    }
  })
  return price
}

const getCalculatedParams = (checkedVariantArr, item) => {
  const {
    options = [],
  } = checkedVariantArr

  const {
    baseOptions = [],
  } = item

  let arr = []

  options.forEach(option => {
    const itemOption = baseOptions.find((select => select.id === option.optionId && ((select?.state === 'variant_option') || ((!select.type.includes('Text') && select?.values?.length) || select.type.includes('Checkbox')) || (select.partial === 'date' && option?.optionValue))))

    if (itemOption) {
      if (itemOption.partial === 'date') {
        const date = new Date(+option.optionValue * 1000)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()

        const dateArr = [
          {
            option_id: option.optionId,
            value_id: month,
          },
          {
            option_id: option.optionId,
            value_id: year,
          },
          {
            option_id: option.optionId,
            value_id: day,
          },
        ]
        arr = [...arr, ...dateArr]
      } else {
        arr.push({
          option_id: option.optionId,
          value_id: +option.optionValue,
        })
      }
    }
  })

  return [
    {
      product_id: +checkedVariantArr.productId,
      variant_id: +checkedVariantArr.variantId,
      options: arr,
    },
  ]
}

export {
  getCalculatedPrice,
  getModifierAdjuster,
  getCalculatedParams,
  getNewBulkPrice,
}
